import { useMutation } from "@tanstack/react-query"
import axios from '../../utils/axios';
import { API_URLS } from '../../utils/helper';



export const getToken = async (accountId,userId,userRoleName) => {
  try {
    const response = await axios.get(`${API_URLS.auth}/generate-token/${accountId}/${userId}/${userRoleName}`);
    return response.data
   
  } catch (error) {
    console.log(error);
  }
};


export const changeUserPassword= async (details) => {
  const {accountId,email, userId, oldPassword, newPassword, confirmNewPassword} = details
  const payload = {accountId, userId, oldPassword, newPassword, confirmNewPassword,email}
  try {
    const response = await axios.post(`${API_URLS.auth}/reset-password/${accountId}`,payload)
    return response.data
    } catch (error) {
    return error.message
  }
 
}

export const useChangeUserPassword= () =>
useMutation(changeUserPassword)

