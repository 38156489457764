import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';

// @mui
import { alpha } from '@mui/material/styles';
import { Avatar, Box, Divider, Typography, ListItemText, ListItemAvatar, MenuItem, Link, Chip } from '@mui/material';
// utils
import { useDispatch,useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import BadgeStatus from '../../../components/BadgeStatus';
import { IconButtonAnimate } from '../../../components/animate';
import useAuth from '../../../hooks/useAuth';
import { getToken } from '../../../api/auth';
import { isValidToken, setSession } from '../../../utils/jwt';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
import LoadingScreen from '../../../components/LoadingScreen';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 20;

// ----------------------------------------------------------------------

export default function ContactsPopover() {
  const navigate = useNavigate();
  const isMountedRef = useIsMountedRef();
  const { user: authUser,dispatch } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { userAccounts, activeAccount, _id: defaultUserId } = authUser;
  const [open, setOpen] = useState(null);
  // const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [newAccount, setNewAccount] = useState(null);
  const [loading, setLoading] = useState(false);
//   const { user, isAuthenticated } = useSelector((state) => state.auth);
// console.log('Updated user:', user, 'isAuthenticated:', isAuthenticated);
  const handleOpen = useCallback((event) => setOpen(event.currentTarget), []);
  const handleClose = useCallback(() => setOpen(null), []);

  //  const loadAccountSwitch = useCallback(
  //   async (accountId, userId, userRoleName) => {
  //     if (!accountId || !userId || !userRoleName) return;

  //     try {
  //       setLoading(true);
  //       const results = await getToken(accountId, userId, userRoleName);
  //       const { accessToken, user } = results.data;
  //        console.log('after account switch', user)
  //       if (isMountedRef.current) {
  //         setLoading(false);
  //         handleClose();
  //       }

  //       if (isValidToken(accessToken)) {
  //         setSession(accessToken);
  //         dispatch({
  //           type: 'LOGIN',
  //           payload: { user },
  //         });
  //         setSearchParams({}, { replace: true });

  //         navigate(`${PATH_DASHBOARD.general.app}`);
  //       } else {
  //         throw new Error(results.data?.message || 'Invalid token');
  //       }
  //     } catch (error) {
  //       setLoading(false);
  //       enqueueSnackbar(error.message, { variant: 'error' });
  //     }
  //   },
  //   [dispatch, enqueueSnackbar, isMountedRef, navigate, setSearchParams]
  // );

  // Run the account switcher when newAccount changes
  // useEffect(() => {
  //   if (newAccount) {
  //     loadAccountSwitch(newAccount.accountId, defaultUserId, newAccount.userRoleName);
  //   }
  // }, [newAccount, defaultUserId, loadAccountSwitch]);

  // const handleAccountSwitch = useCallback(
  //   (account) => {
  //     const { _id: accountId, roleName: userRoleName } = account;
  //     console.log({activeAccountId:activeAccount._id, accountId })
  //     if (accountId && accountId !== activeAccount._id) {
  //       setSearchParams((prev) => {
  //         prev.set('accountId', accountId);
  //         return new URLSearchParams(prev);
  //       });
  //       setNewAccount({ accountId, userRoleName,defaultUserId });
  //     }
  //   },
  //   [newAccount, setSearchParams]
  // );

  const loadAccountSwitch = useCallback(
    async (accountId, userId, userRoleName) => {
      if (!accountId || !userId || !userRoleName) return;
      try {
        setLoading(true);
        const results = await getToken(accountId, userId, userRoleName);
        const { user: apiUser,accessToken } = results.data;
        
       
        // handleClose();
        setLoading(false);
        if (!apiUser || typeof apiUser !== 'object' || !apiUser._id) { // Adjust based on your user object structure
          // console.error('Invalid apiUser:', apiUser);
          return;
        }

         if (isMountedRef.current) {
          handleClose();
          setSession(accessToken);
          dispatch({ type: 'UPDATE_USER', 
            payload: { 
              user: apiUser 
            } 
          });
        }
       
        // console.log('After dispatching LOGIN action',authUser);
          // window.location.reload();
          // console.log('Dispatched action:', { type: 'LOGIN', payload: { user: apiUser } });

          navigate(`${PATH_DASHBOARD.general.app}`);
          // console.error('after navigating');
          // setSearchParams({}, { replace: true });
          handleClose();
       
      } catch (error) {
        setLoading(false);
        // console.error('Error in loadAccountSwitch:', error);
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
    [dispatch,  isMountedRef]
  );
  
  useEffect(() => {
    if (newAccount) {
      loadAccountSwitch(newAccount.accountId, defaultUserId, newAccount.userRoleName);
    }
  }, [newAccount, defaultUserId, loadAccountSwitch]);

  // const handleAccountSwitch = useCallback(
  //   (account) => {
  //     const { _id: accountId, roleName: userRoleName } = account;
  //     console.log({ activeAccountId: activeAccount._id, accountId });
  //     if (accountId && accountId !== activeAccount._id) {
  //       setSearchParams((prev) => {
  //         prev.set('accountId', accountId);
  //         return new URLSearchParams(prev);
  //       });
  //       setNewAccount({ accountId, userRoleName, defaultUserId });
  //     }
  //   },
  //   [newAccount,setSearchParams, ]
  // );
  const handleAccountSwitch =  (account) => {
    const { _id: accountId, roleName: userRoleName } = account;
    // console.log({ activeAccountId: activeAccount._id, accountId });
    if (accountId && accountId !== activeAccount._id) {
      setSearchParams((prev) => {
        prev.set('accountId', accountId);
        return new URLSearchParams(prev);
      });
      setNewAccount({ accountId, userRoleName, defaultUserId });
    }
  }

  if (loading) {
    return <LoadingScreen />;
  }

  const createInitials = (name) => name && name.match(/(\b\S)?/g).join('');

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <Iconify icon={'eva:people-fill'} width={20} height={20} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 320,
          '& .MuiMenuItem-root': {
            px: 1.5,
            height: ITEM_HEIGHT,
            borderRadius: 0.75,
          },
        }}
      >
        <Typography variant="h6" sx={{ p: 1.5 }}>
          Switch Account
        </Typography>

        <Scrollbar sx={{ height: userAccounts?.length * 10 * 6 || ITEM_HEIGHT }}>
          {/* {renderUserType()} */}
          {userAccounts?.map((userAccount) => {
            const isActive = activeAccount.displayName === userAccount.displayName;
            return (
              <Link onClick={() => handleAccountSwitch(userAccount)} key={userAccount.displayName}>
                <Box
                  sx={{
                    my: 1.5,
                    px: 2.5,
                    '&:hover': {
                      backgroundColor: '#edf0f7',
                      overflow: 'hidden',
                      opacity: [0.9, 0.8, 0.7],
                    },
                    position: 'relative',
                  }}
                >
                  <Chip
                    avatar={<Avatar>{createInitials(userAccount?.displayName)}</Avatar>}
                    label={userAccount?.displayName}
                    color={isActive ? 'success' : 'default'}
                  />
                </Box>
                <Divider sx={{ borderStyle: 'dashed' }} />
              </Link>
            );
          })}
        </Scrollbar>
      </MenuPopover>
    </>
  );
}
