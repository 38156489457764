import { format, getTime, formatDistanceToNow,formatDuration} from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function CurrentDate(date) {
  return format(new Date(date), 'iiii, MMMM dd');
}

export function fSeconds(dateString){
const durationInMs = new Date() - new Date(dateString);
const durationInMins = Math.floor(durationInMs / 60000); 
const duration = { minutes: durationInMins }; 
const formattedDur = formatDuration(duration, { format: ['minutes'] }).replace('minutes', 'min').replace('minute','min');

  return formattedDur!=='' ?formattedDur : '0min';
  
}