import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import RoleBasedGuard from '../guards/RoleBasedGuard';
import useAuth from '../hooks/useAuth';
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

import BlackListNumbers from '../pages/dashboard/adminsettings/BlackListNumbers';
import { Can, Switch as PermissionsSwitch } from '../guards/PermissionGuard';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { user } = useAuth();
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'reset-password/:token/:userId', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'verify-email/:token/:userId', element: <VerifyEmail /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        //  { path: 'sender-names', element: <GeneralSenderNames /> },
        // { path: 'analytics', element: <GeneralAnalytics /> },
        // { path: 'banking', element: <GeneralBanking /> },
        // { path: 'booking', element: <GeneralBooking /> },

        // {
        //   path: 'e-commerce',
        //   children: [
        //     { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
        //     { path: 'shop', element: <EcommerceShop /> },
        //     {
        //       path: 'product/:name',
        //       element: (
        //         <RoleBasedGuard>
        //           <EcommerceProductDetails />
        //         </RoleBasedGuard>
        //       ),
        //     },
        //     { path: 'list', element: <EcommerceProductList /> },
        //     { path: 'product/new', element: <EcommerceProductCreate /> },
        //     { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
        //     { path: 'checkout', element: <EcommerceCheckout /> },
        //     { path: 'invoice', element: <EcommerceInvoice /> },
        //   ],
        // },
        {
          path: 'admin',
          children: [
            { element: <Navigate to="/dashboard/admin/account" replace />, index: true },
            {
              path: 'profile',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <UserProfile />
                </RoleBasedGuard>
              ),
            },
            { path: 'sender-names', element: <UserSenderNames /> },
            { path: 'users-list', element: <UserList /> },
            { path: 'transactions', element: <UserTransactions /> },
            { path: 'account', element: <UserAccount /> },
            { path: 'accounts-list', element: <AccountsList /> },
          ],
        },
        {
          path: 'configurations',
          children: [
            { element: <Navigate to="/dashboard/configurations/defaultSettings" replace />, index: true },
            {
              path: 'default-settings',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <UserProfile />
                </RoleBasedGuard>
              ),
            },
            { path: 'spam-filter', element: <UserCards /> },
            { path: 'global-spam', element: <UserList /> },
            { path: 'manage-notifications', element: <UserCreate /> },
            { path: 'manage-emails', element: <UserCreate /> },
          ],
        },
        {
          path: 'adminSettings',
          children: [
            { element: <Navigate to="/dashboard/adminSettings/blacklist-numbers" replace />, index: true },
            { path: 'blacklist-numbers', element: <BlackListNumbers /> },
            { path: 'spam-filter', element: <UserCards /> },
            { path: 'global-spam', element: <UserList /> },
            { path: 'blacklisted-sendernames', element: <BlacklistSenders /> },
            { path: 'site-branding', element: <SiteBranding /> },
            { path: 'features', element: <AppFeatures /> },
            { path: 'mobile-networks', element: <MobileNetworks /> },
            { path: 'country-settings', element: <CountrySettings /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new-post', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'messaging',
          children: [
            { element: <Navigate to="/dashboard/messaging/billing-history" replace />, index: true },
            { path: 'bulk', element: <BulkMessages /> },
            { path: 'personalized', element: <PersonalisedMessages /> },
            { path: 'sender-names', element: <SenderNames /> },
            {path :'contact-group' ,element:<ContactAndGroup />},
            {path:'contact-group/:groupId/contacts', element: <GetContactsByGroupId />},
            {path: 'recharge',element: <SMSRecharge />},
            {path:'billing-history',element: <RechargeHistory /> , },
            {path:'messages-reports',element: <MessagesReport /> , },
                 
          ],
        },
        {
          path: 'settings',
          children: [
            { element: <Navigate to="/dashboard/settings/developer" replace />, index: true },
            { path: 'developer', element: <DeveloperConsole /> },
            { path: 'developer/:appId/activity', element: <DeveloperActivityLogs /> },     
            { path: 'sub-accounts', element: <SubAccounts /> },
            { path: 'role-permissions-list', element: (<PermissionsSwitch><Can permissions={['subaccounts:view']}><RolePermissionsList /></Can></PermissionsSwitch>) },
            { path: 'role-permissions/:roleId/edit', element: <RolePermissionsCreate /> },
            { path: 'role-permissions/create', element: <RolePermissionsCreate /> },
            { path: 'account-users', element: <AccountUsersList /> },
            { path: 'sub-accounts/users/:accountId', element: (<SubAccountUsers />  )},
     
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
        {path: 'mobile-app',element: <MobileSimulator />}
        // { path: 'recharge', children:[
        //   {element: <RechargeHistory /> , index:true},
        //   {element: <RechargeType /> , path: 'types'},
        //   {element: <RechargeBundles /> , path: 'bundles'},
        //   {element: <RechargeAsPayAsYouGo /> , path: 'pay-as-you-go'},


        // ] },
    
      ],
    },

    // Main Routes
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ForgotPassword = Loadable(lazy(() => import('../pages/auth/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const VerifyEmail = Loadable(lazy(() => import('../pages/auth/VerifyEmail')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const SenderNames = Loadable(lazy(() => import('../sections/app/messaging/SenderNames')));
const BlacklistSenders = Loadable(lazy(() => import('../pages/dashboard/adminsettings/BlacklistSender')));
const AppFeatures = Loadable(lazy(() => import('../pages/dashboard/adminsettings/Features')));
const MobileNetworks = Loadable(lazy(() => import('../pages/dashboard/adminsettings/MobileNetworks')));
const CountrySettings = Loadable(lazy(() => import('../pages/dashboard/adminsettings/CountrySettings')));
// const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
// const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
// const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
// const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/useradministration/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/useradministration/UserAccount')));
const AccountsList = Loadable(lazy(() => import('../pages/dashboard/useradministration/AccountsList')));
const UserTransactions = Loadable(lazy(() => import('../pages/dashboard/useradministration/UserTransactions')));
const UserSenderNames = Loadable(lazy(() => import('../pages/dashboard/useradministration/UserSenderNames')));
const SiteBranding = Loadable(lazy(() => import('../pages/dashboard/adminsettings/SiteBranding')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// App - Messaging
const BulkMessages = Loadable(lazy(() => import('../sections/app/messaging/BulkMessages')));
const PersonalisedMessages = Loadable(lazy(() => import('../sections/app/messaging/PersonalisedMessages')));
const MessagesReport = Loadable(lazy(() => import('../sections/app/messaging/MessagesReport')))

// Client - Dashboard
const ContactAndGroup = Loadable(lazy(() => import('../pages/dashboard/contacts_n_groups/ContactGroup')))
const GetContactsByGroupId = Loadable(lazy(() => import('../pages/dashboard/contacts_n_groups/GetContactsByGroupId')))

// Client -Developer Console
const DeveloperConsole = Loadable(lazy(() => import('../pages/dashboard/developers/Developer')))
const DeveloperActivityLogs = Loadable(lazy(() => import('../pages/dashboard/developers/GetAppActivityLog')))
// Client -Recharge History
// const RechargeHistory = Loadable(lazy(() => import('../pages/dashboard/recharge_history/Recharge')))
const RechargeType = Loadable(lazy(() => import('../pages/dashboard/recharge_history/RechargeType')))
const RechargeBundles = Loadable(lazy(() => import('../pages/dashboard/recharge_history/RechargeBundle')))
const RechargeHistory = Loadable(lazy(() => import('../sections/app/messaging/RechargeHistory')))
const SMSRecharge = Loadable(lazy(() => import('../sections/app/messaging/AddFundToAccount')))
const MobileSimulator = Loadable(lazy(() => import('../pages/mobile_app/AndroidSimulator')))
// const SubAccounts = Loadable(lazy(() => import('../pages/dashboard/sub_accounts/SubAccounts')))
const SubAccounts = Loadable(lazy(() => import('../pages/dashboard/sub_accounts/SubAccounts')))
const SubAccountUsers = Loadable(lazy(() => import('../pages/dashboard/sub_accounts/SubAccountUsersList')))
const RolePermissionsCreate = Loadable(lazy(() => import('../pages/dashboard/settings/Role/RolePermissionsCreate')))
const RolePermissionsList = Loadable(lazy(() => import('../pages/dashboard/settings/Role/RolePermissionsList')))
const AccountUsersList = Loadable(lazy(() => import('../pages/dashboard/settings/AccountUsers/AccountUsersList')))
