import { useMutation, useQuery } from '@tanstack/react-query';
import axios from '../../utils/axios';
import { API_URLS } from '../../utils/helper';

const getAllBlacklistedSenders = async (accountId) => {
  // const authToken = await getToken()
  const response = await axios.get(`${API_URLS.blacklistedSenderNamesUrl}/${accountId}`);
  return response.data.data;
};

export const useGetBlacklistedSenderNames = (accountId) =>
  useQuery(['blacklisted-sender-names',accountId], () => getAllBlacklistedSenders(accountId));

const updateBlacklistedSenderNames = async (countryDetails) => {
  const { country, senderNames,accountId } = countryDetails;
  const payload = { country, names:senderNames };
  const response = await axios.put(`${API_URLS.blacklistedSenderNamesUrl}/update/${accountId}`, payload);
  return response.data;
};

export const useUpdateBlacklistedSenderNames = () => useMutation(updateBlacklistedSenderNames);

const saveBlacklistedSenderNames = async (countryDetails) => {
  const { country, senderNames,accountId } = countryDetails;
  const payload = { country, names:senderNames };
  const response = await axios.post(`${API_URLS.blacklistedSenderNamesUrl}/save/${accountId}`, payload);

 return response.data;
};

export const useSaveBlacklistedSenderNames = () => useMutation(saveBlacklistedSenderNames);

const getAllBlacklistedNumbers = async (accountId) => {
  // const authToken = await getToken()
  const response = await axios.get(`${API_URLS.blacklistedNumbers}/${accountId}`);
  return response.data.data;
};

export const useGetBlacklistedNumbers = (accountId) => useQuery(['blacklisted-numbers',accountId], () => getAllBlacklistedNumbers(accountId));

const updateBlacklistedNumbers = async (countryDetails) => {
  const { country, numbers } = countryDetails;
  const payload = { country, numbers };
  const response = await axios.post(`${API_URLS.blacklistedNumbers}/update`, payload);
  return response.data;
};

export const useUpdateBlacklistedNumbers = () => useMutation(updateBlacklistedNumbers);

const saveBlacklistedNumbers = async (countryDetails) => {
  const { country, numbers } = countryDetails;
  const payload = { country, numbers };
  const response = await axios.post(`${API_URLS.blacklistedNumbers}/save`, payload);
  return response.data;
};

export const useSaveBlacklistedNumbers = () => useMutation(saveBlacklistedNumbers);

const getAllPermissions = async (userId, accountId) => {
  const response = await axios.get(`${API_URLS.permissions}/${userId}/${accountId}`);
  return response.data.data;
};

export const useGetAllPermissions = (userId, accountId) =>
  useQuery(
    ['user-id-permissions', userId, accountId],
    () => getAllPermissions(userId, accountId),
    {
      // 🚀 only server errors will go to the Error Boundary
      useErrorBoundary: (error) => error,
      // error.response?.status >= 400,
    }
  );


  const savePermissions = async (permissions) => {
    const {  title, labels,userId,accountId,description } = permissions;
    const payload = {title, labels,userId,accountId,description };
    const response = await axios.post(`${API_URLS.permissions}/save/${accountId}/${userId}`, payload);
    return response.data;
  };

  export const useSavePermissions = () => useMutation(savePermissions);


  const updatePermissions = async (permissions) => {
    const { _id, title, labels,userId,accountId,description } = permissions;
    const payload = { _id, title, labels,userId,accountId,description }
    const response = await axios.put(`${API_URLS.permissions}/update/${accountId}/${userId}`, payload);
    return response.data;
  };
  
  export const useUpdatePermissions = () => useMutation(updatePermissions);

  const getAllMobileNetworks = async (accountId) => {
    // const authToken = await getToken()
    const response = await axios.get(`${API_URLS.mobileNetworks}/get-all/${accountId}`);
    return response.data.data;
  };

  export const useGetAllMobileNetworks = (accountId) =>
  useQuery(['get-mobile-networks',accountId], () => getAllMobileNetworks(accountId));

  const saveMobileNetwork = async (networkDetails) => {
    const { country, networkName,networkCode,userId,accountId } = networkDetails;
    const payload = { country, networkName,networkCode,userId };
    const response = await axios.post(`${API_URLS.mobileNetworks}/save/${accountId}`, payload);
    return response.data;
  };

  export const useSaveMobileNetwork = () => useMutation(saveMobileNetwork);

  const updateMobileNetwork = async (networkDetails) => {
    const { country, networkName,networkCode,userId,mobileNetworkId,accountId } = networkDetails;
    const payload = { country, networkName,networkCode,userId,mobileNetworkId };
    const response = await axios.post(`${API_URLS.mobileNetworks}/update/${accountId}`, payload);
    return response.data;
  };

  export const useUpdateMobileNetwork = () => useMutation(updateMobileNetwork);

  const getAllCountrySettings = async (accountId) => {
    // const authToken = await getToken()
    const response = await axios.get(`${API_URLS.countrySettings}/${accountId}`);
    return response.data.data;
  };
  
  export const useGetAllCountrySettings = (accountId) =>
    useQuery(['country-settings',accountId], () => getAllCountrySettings(accountId));

  const saveCountrySettings = async (details) => {
    const response = await axios.post(`${API_URLS.countrySettings}/save/${details.accountId}`, details);
    return response.data;
  };

  export const useSaveCountrySettings = () => useMutation(saveCountrySettings);


  const getOneCountrySettings = async (accountId,countryCode) => {
    // const authToken = await getToken()
    const response = await axios.get(`${API_URLS.countrySettings}/${accountId}/${countryCode}`);
    return response.data.data;
  };
  
  export const useGetOneCountrySettings = (accountId,countryCode) =>
    useQuery(['one-country-settings',accountId,countryCode], () => getOneCountrySettings(accountId,countryCode));