import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
import Image from "./Image";

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const logo = (
    <Box sx={{ width: 140, height: 50, ...sx, color:'blue' }}>
      <Image
        alt="register"
        src="https://celenity-brand.s3.sa-east-1.amazonaws.com/celenity-brand-logo.svg"
        // src="/logo/celenitylogo.png"
        style={{ width: '100%', height: '100%',flex: 1 }}
      />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
