import React from 'react';
import {  useFormContext, Controller } from 'react-hook-form';
import {  FormControlLabel, FormGroup, Checkbox} from '@mui/material';
import PropTypes from 'prop-types';

MultiCheckBox.propTypes = {
  name: PropTypes.string,
  // options: PropTypes.array({label:PropTypes.string,value:PropTypes.string})
};

export default function MultiCheckBox({ name, options, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const onSelected = (option) =>field.value?.includes(option)
          ? field.value.filter((value) => value !== option)
          : [...field.value, option]
         
        return (
          <FormGroup>
            {options?.map((option) => (
                <FormControlLabel
                  key={option.label}
                  control={
                    <Checkbox
                      checked={field.value?.includes(option.value)}
                      onChange={() => field.onChange(onSelected(option.value))}
                    />
                  }
                  label={option.label}
                  {...other}
                />
              ))}
          </FormGroup>
        );
      }}
    />
  );
}
