// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  verify: path(ROOTS_AUTH, '/verify'),
  verifyEmail: path(ROOTS_AUTH, '/verify-email'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    senderNames: path(ROOTS_DASHBOARD, '/sender-names'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey'),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  admin: {
    root: path(ROOTS_DASHBOARD, '/admin'),
    profile: path(ROOTS_DASHBOARD, '/admin/profile'),
    transactions: path(ROOTS_DASHBOARD, '/admin/transactions'),
    cards: path(ROOTS_DASHBOARD, '/admin/cards'),
    list: path(ROOTS_DASHBOARD, '/admin/list'),
    newUser: path(ROOTS_DASHBOARD, '/admin/new'),
    editById: path(ROOTS_DASHBOARD, `/admin/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/admin/account'),
    userList: path(ROOTS_DASHBOARD, '/admin/users-list'),
    senderNames: path(ROOTS_DASHBOARD, '/admin/sender-names'),
    accountList: path(ROOTS_DASHBOARD, '/admin/accounts-list'),
  },
  adminSettings: {
    root: path(ROOTS_DASHBOARD, '/adminSettings'),
    blacklistNumbers: path(ROOTS_DASHBOARD, '/adminSettings/blacklist-numbers'),
    siteBranding: path(ROOTS_DASHBOARD, '/adminSettings/site-branding'),
    spamFilter: path(ROOTS_DASHBOARD, '/adminSettings/spam-filter'),
    manageNotifications: path(ROOTS_DASHBOARD, '/adminSettings/spam-filter'),
    blacklistedSenderNames: path(ROOTS_DASHBOARD, '/adminSettings/blacklisted-sendernames'),
    features: path(ROOTS_DASHBOARD, '/adminSettings/features'),
    mobileNetworks: path(ROOTS_DASHBOARD, '/adminSettings/mobile-networks'),
    countrySettings: path(ROOTS_DASHBOARD, '/adminSettings/country-settings'),
  },
  configurations: {
    root: path(ROOTS_DASHBOARD, '/configurations'),
    defaultSettings: path(ROOTS_DASHBOARD, '/configurations/default-settings'),
    spamFilter: path(ROOTS_DASHBOARD, '/configurations/spam-filter'),
    globalSpam: path(ROOTS_DASHBOARD, '/configurations/global-spam'),
    manageNotifications: path(ROOTS_DASHBOARD, '/configurations/manage-notifications'),
    manageEmails: path(ROOTS_DASHBOARD, `/configurations/manage-emails`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post'),
  },
  messaging: {
    root: path(ROOTS_DASHBOARD, '/messaging'),
    bulkMessages: path(ROOTS_DASHBOARD, '/messaging/bulk'),
    personalized: path(ROOTS_DASHBOARD, '/messaging/personalized'),
    senderNames: path(ROOTS_DASHBOARD, '/messaging/sender-names'),
    groupContact: path(ROOTS_DASHBOARD, '/messaging/contact-group'),
    groupById: path(ROOTS_DASHBOARD, '/messaging/contact-group/:groupId/contacts'),
    recharge: path(ROOTS_DASHBOARD, '/messaging/recharge'),
    billingHistory: path(ROOTS_DASHBOARD, '/messaging/billing-history'),
    messagesReports: path(ROOTS_DASHBOARD, '/messaging/messages-reports'),
  },
  settings: {
    root: path(ROOTS_DASHBOARD, '/settings'),
    developer: path(ROOTS_DASHBOARD, '/settings/developer'),
    developerLog: path(ROOTS_DASHBOARD, '/settings/{appId}/activity'),
    personalized: path(ROOTS_DASHBOARD, '/settings/personalized'),
    subAccounts: path(ROOTS_DASHBOARD, '/settings/sub-accounts'),
    rolePermissionsList: path(ROOTS_DASHBOARD, '/settings/role-permissions-list'),
    rolePermissionsEdit: path(ROOTS_DASHBOARD, '/settings/role-permissions/:roleId/edit'),
    rolePermissionsCreate: path(ROOTS_DASHBOARD, '/settings/role-permissions/create'),
    rolePermissions: path(ROOTS_DASHBOARD, '/settings/role-permissions'),
    accountUsers: path(ROOTS_DASHBOARD, '/settings/account-users'),
    subAccountUsersList: path(ROOTS_DASHBOARD, '/settings/sub-accounts/users/:accountId'),
  },
  recharge: {
    root: path(ROOTS_DASHBOARD, '/recharge'),
    rechargeType: path(ROOTS_DASHBOARD, '/recharge/types'),
    bundle: path(ROOTS_DASHBOARD, '/recharge/bundles'),
    payAsYouGo: path(ROOTS_DASHBOARD, '/recharge/pay-as-you-go'),
  },
  mobile_app: { root: path(ROOTS_DASHBOARD, '/mobile-app') },
  // developer:{
  //   root:path(ROOTS_DASHBOARD, '/developer'),

  // }
};

export const PATH_DOCS = 'https://developer.celenity.net';
