import { createContext, useEffect, useReducer,useMemo } from 'react';
import PropTypes from 'prop-types';
// utils
import jwtDecode from 'jwt-decode';
import merge from 'lodash/merge';
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import { HOST_API } from '../config';

// ----------------------------------------------------------------------
const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  registerUser: null,
  activeAccountId: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
  
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },

  UPDATE_USER: (state, action) => {
    const { user } = action.payload;
    // console.log('State before UPDATE_USER:', state);
    // console.log('Payload in UPDATE_USER:', user);
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },

  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),

  REGISTER: (state, action) => {
    const { registerUser } = action.payload;

    return {
      ...state,
      isAuthenticated: false,
      registerUser,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyUserPhoneNumber: () => Promise.resolve(),
  completeRegistration: () => Promise.resolve(),
  verifyRegistrationToken: () => Promise.resolve(),
  verifyRecaptcha: () => Promise.resolve(),
  updateUser: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  verifyForgotPasswordToken: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),


});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  // console.log('HOST_API',HOST_API);

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const decoded = jwtDecode(accessToken);
         
          const url = `${HOST_API}/accounts/account/${decoded.userId}/${decoded.accountId}/${decoded.userRoleName}`;
          try {
            const response = await axios.get(url);
            const {user} = response.data.data;
            // console.log('user in initial state', user);
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                user,
              },
            });
          } catch (error) {
            console.error('Failed to fetch user details:', error);
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
          }
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error('Failed to fetch user details:', err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

  initialize();
  }, []);

  const login = async (email, password) => {
    const url = `${HOST_API}/harbor/board-ship`;
    const response = await axios.post(url, {
      email,
      password,
    });
    if (!response || response?.data === undefined || response?.data?.code === 1) {
      if (!response) {
        throw new Error('Could not make request');
      }
      throw new Error(response.data?.message);
    }

    const { accessToken, user } = response.data.data;
    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const verifyRecaptcha = async (code) => {
    const url = `${HOST_API}/harbor/verify-google-captcha`;
    try {
      const response = await axios.post(url,{reCAPTCHA_TOKEN:code});
      if(!response) return false
      if (response?.data?.data.captchaToken) {
        return true;
      }
      return false;
    } catch (error) {
      return error
    }
   
  };

  const register = async (payload) => {
    // console.log('register payload',payload)
    const url = `${HOST_API}/harbor/register-user`;
    if (payload?.developerType === 'Yes') {
      payload.developerType = true;
    } else {
      payload.developerType = false;
    }
    const response = await axios.post(url, { ...payload });
    const { data, message, code } = response?.data;
    if (code === 1) {
      return { message, code };
    }
    if (code === 0) {
      const { user, accessToken } = data;

      // window.localStorage.setItem('accessToken', accessToken);
      dispatch({
        type: 'REGISTER',
        payload: {
          registerUser: user,
        },
      });
      // console.log({ message, code });

      return { message, code };
    }
  };

  const verifyUserPhoneNumber = async (payload) => {
    const url = `${HOST_API}/harbor/verify-phone-number`;
    try {
      const response = await axios.post(url, { ...payload });
      return response;
    } catch (error) {
      return error
    }
   
  };

  const verifyRegistrationToken = async (payload) => {
    const url = `${HOST_API}/harbor/verify-registration-token`;
    try {
      const response = await axios.post(url, { ...payload });
      return response.data;
    } catch (error) {
      // console.log('error', error.message);
    }
  };
  const completeRegistration = async (payload) => {
    const url = `${HOST_API}/harbor/activate`;

    const response = await axios.post(url, { ...payload });
    return response;
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };
  const updateUser = async (updatedUser) => {
    dispatch({
      type: 'UPDATE_USER',
      payload: {
        user: updatedUser,
      },
    });
  };

  const forgotPassword = async (email) => {
    const url = `${HOST_API}/harbor/forgot-password`;
   try {
     const response = await axios.post(url, { email });
    return response.data;
   } catch (error) {
    return error
   }
   
  };
  const verifyForgotPasswordToken = async (payload) => {
    const url = `${HOST_API}/harbor/verify-forgot-password-token`;
   try {
     const response = await axios.post(url, { ...payload });
    return response.data;
   } catch (error) {
    return error
   }
   
  };
  const resetPassword = async (payload) => {
    const url = `${HOST_API}/harbor/reset-password`;
   try {
     const response = await axios.post(url, { ...payload });
    return response.data;
   } catch (error) {
    return error
   }
   
  };

  // const logout = async () => {
  //   setSession(null);
  //   dispatch({ type: 'LOGOUT' });
  // };

  const contextValue = useMemo(
    () => ({
      ...state,
      dispatch,
      method: 'jwt',
      login,
      logout,
      register,
      verifyUserPhoneNumber,
      completeRegistration,
      verifyRegistrationToken,
      verifyRecaptcha,
      updateUser,
      forgotPassword,
      verifyForgotPasswordToken,
      resetPassword,
      
    }),
    [state]
  );
  return (
    <AuthContext.Provider
      value={contextValue}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
