import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';

// @mui
import { alpha } from '@mui/material/styles';
import { Avatar, Box, Divider, Typography, ListItemText, ListItemAvatar, MenuItem, Link, Chip } from '@mui/material';
// utils
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import BadgeStatus from '../../../components/BadgeStatus';
import { IconButtonAnimate } from '../../../components/animate';
import useAuth from '../../../hooks/useAuth';
import { getToken } from '../../../api/auth';
import { isValidToken, setSession } from '../../../utils/jwt';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
import LoadingScreen from '../../../components/LoadingScreen';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 20;

// ----------------------------------------------------------------------

export default function OperatingCountries() {
  const navigate = useNavigate();
  const isMountedRef = useIsMountedRef();
  const { user, updateUser } = useAuth();
  const { operatingCountries } = user;
  const [open, setOpen] = useState(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [activeCountry, setActiveCountry] = useState('');
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };

  // useEffect(() => {
  //   function loadAccountCountry(userCountry) {
  //     // request for token
  //     setLoading(true);
  //     const existingUser = JSON.stringify(user);
  //     const newUser = JSON.parse(existingUser);
  //     newUser.country = userCountry;
  //     console.log({ newUser });
  //     setLoading(false);
  //     if (activeCountry !== user.country) {
  //       dispatch({
  //         type: 'UPDATE_USER',
  //         payload: {
  //           user: newUser,
  //         },
  //       });
  //       navigate(`${PATH_DASHBOARD.root}`);
  //       window.location.reload();
  //     }
  //     if (isMountedRef.current) {
  //       handleClose();
  //     }
  //   }
  //   loadAccountCountry(activeCountry);
  // }, []);

  const handleSwitchCountry = async (userCountry) => {
    console.log({ userCountry });
    setActiveCountry(userCountry);
    const existingUser = JSON.stringify(user);
    const updatedUser = JSON.parse(existingUser);
    updatedUser.country = userCountry;
    console.log({ updatedUser });
    updateUser(updatedUser)
    navigate(`${PATH_DASHBOARD.root}`);
    window.location.reload();
    // TODO write api to update user country
  };

  if (loading) {
    return <LoadingScreen />;
  }

  const createInitials = (name) => name && name.match(/(\b\S)?/g).join('');
  console.log({user})
  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <Iconify icon={'eva:people-fill'} width={20} height={20} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 320,
          '& .MuiMenuItem-root': {
            px: 1.5,
            height: ITEM_HEIGHT,
            borderRadius: 0.75,
          },
        }}
      >
        <Typography variant="h6" sx={{ p: 1.5 }}>
          Change Country
        </Typography>

        <Scrollbar sx={{ height: operatingCountries?.length * 10 * 6 || ITEM_HEIGHT * 6 }}>
          {/* {renderUserType()} */}
          {operatingCountries?.map((userCountry) => {
            const isActive = user.country === userCountry;
            return (
              <Link onClick={() => handleSwitchCountry(userCountry)} key={userCountry}>
                <Box
                  sx={{
                    my: 1.5,
                    px: 2.5,
                    '&:hover': {
                      backgroundColor: '#edf0f7',
                      overflow: 'hidden',
                      opacity: [0.9, 0.8, 0.7],
                    },
                    position: 'relative',
                  }}
                >
                  <Chip
                    avatar={<Avatar>{createInitials(userCountry)}</Avatar>}
                    label={userCountry}
                    color={isActive ? 'success' : 'default'}
                  />
                </Box>
                <Divider sx={{ borderStyle: 'dashed' }} />
              </Link>
            );
          })}
        </Scrollbar>
      </MenuPopover>
    </>
  );
}
