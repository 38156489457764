import { useEffect, useState } from 'react';

import { useTheme } from '@mui/material/styles';

import {
  Container,
  Card,
  Table,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Button,
} from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Page from '../../../components/Page';
// sections
import { ListHead, ListToolbar } from '../../../components/dataTable/list';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import useLocales from '../../../hooks/useLocales';
import { PATH_DASHBOARD } from '../../../routes/paths';
import BlackListNumberModel from '../../../sections/@dashboard/admin_settings/BlackListNumberModel';
import {
  useGetBlacklistedNumbers,
  useUpdateBlacklistedNumbers,
  useSaveBlacklistedNumbers,
} from '../../../api/adminSettings';
import { LoadingStatus } from '../../../utils/helper';
import LoadingScreen from '../../../components/LoadingScreen';
import useAuth from '../../../hooks/useAuth';

function BlackListNumbers() {
  const TABLE_HEAD = [
    { id: 'id', label: 'ID' },
    { id: 'country ', label: 'Country', alignRight: false },
    { id: 'contact', label: 'Mobile No.', alignRight: false },
    { id: 'actions', label: 'Action', alignRight: true },
  ];
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const accountId = user?.activeAccount._id;
  const { themeStretch } = useSettings();
  const { translate } = useLocales();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('_id');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [openModel, setOpenModel] = useState(false);
  const { data: blacklisted, status: loadingStatus } = useGetBlacklistedNumbers(accountId);
  const { mutate: updateBlacklistedNumbers, status: updateLoadingStatus } = useUpdateBlacklistedNumbers();
  const { mutate: saveBlacklistedNumbers, status: saveLoadingStatus } = useSaveBlacklistedNumbers();
  const [blacklistedNumbers, setBlacklistedNumbers] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    setBlacklistedNumbers(blacklisted);
  }, [blacklisted]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  // console.log({ blacklisted });
  // console.log({ blacklistedNumbers });
  const editHandler = (row) => {
    // console.log('selected row', row);
    setIsEdit(true);
    setOpenModel((prev) => !prev);
    setSelectedItem(row);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleSelectAllClick = (e) => {
    if (e.target.checked) {
      const newSelecteds = blacklistedNumbers.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - blacklistedNumbers?.length) : 0;

  const filteredData = applySortFilter(blacklistedNumbers, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredData?.length && Boolean(filterName);

  const addNewContactHandler = (data) => {
    let itemId = blacklistedNumbers?.length + 1;
    const newData = data.map((phoneData) => {
      const obj = {
        country: { label: phoneData.label, code: phoneData.code },
        phone: phoneData.phone,
        blackistedId: itemId,
      };
      itemId += itemId;
      // console.log({ obj });
      return obj;
    });
    setBlacklistedNumbers((preState) => [...newData, ...preState]);
  };

  const updateNumbers = (data) => {
    // console.log('in update data', data);
    updateBlacklistedNumbers(
      { ...data },
      {
        onError: (error, variables, context) => {
          // console.log('error in UI=>>', error.message);
          enqueueSnackbar(error.message, {
            variant: 'error',
          });
        },
        onSuccess: (countryResponse) => {
          const { data, message } = countryResponse;
          // console.log({ data });
          setBlacklistedNumbers(data);
          enqueueSnackbar(message);
        },
      }
    );
  };

  const saveNumbers = (data) => {
    // console.log('in saving data', data);
    saveBlacklistedNumbers(
      { ...data },
      {
        onError: (error, variables, context) => {
          console.log('error in UI=>>', error.message);
          enqueueSnackbar(error.message, {
            variant: 'error',
          });
        },
        onSuccess: (countryResponse) => {
          const { data, message, code } = countryResponse;
          console.log({ data });
          if (code === 1) {
            return enqueueSnackbar(message);
          }
          if (code === 0) {
            setBlacklistedNumbers(data);
          }
        },
      }
    );
  };

  if (loadingStatus === LoadingStatus.Loading) {
    return <LoadingScreen />;
  }
  // console.log('reload');
  return (
    <Page title="BlackListed Numbers">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={translate('admin_settings.blacklistedNumbers')}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Admin', href: PATH_DASHBOARD.adminSettings.root },
            { name: translate('admin_settings.blacklistedNumbers') },
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              onClick={() => {
                setOpenModel(true);
                setIsEdit(false);
              }}
              to={''}
              startIcon={<Iconify icon={'eva:plus-fill'} />}
            >
              Number
            </Button>
          }
        />
        <Card>
          <ListToolbar filterData={filterName} onFilterData={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={blacklistedNumbers?.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                    const blacklistedId = i + 1;
                    const { _id, country, numbers } = row;
                    const isItemSelected = selected.indexOf(_id) !== -1;

                    return (
                      <TableRow
                        hover
                        key={blacklistedId}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell align="left">{blacklistedId}</TableCell>

                        <TableCell align="left">{country}</TableCell>
                        <TableCell align="left">
                          {numbers.map((phone, index) => (
                            <Label key={index} color="info" variant="outlined" sx={{ ml: 1 }}>
                              {phone}
                            </Label>
                          ))}
                        </TableCell>

                        <TableCell align="right">
                          <Button
                            variant="outlined"
                            onClick={() => {
                              editHandler(row);
                            }}
                            color="success"
                          >
                            {' '}
                            Edit
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={blacklistedNumbers?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>{' '}
      </Container>

      {isEdit ? (
        <BlackListNumberModel
          openModel={openModel}
          setOpenModel={setOpenModel}
          isEdit={isEdit}
          addNewContactHandler={addNewContactHandler}
          setIsEdit={setIsEdit}
          selectedItem={selectedItem}
          updateNumbers={(data) => updateNumbers(data)}
        />
      ) : (
        <BlackListNumberModel
          openModel={openModel}
          setOpenModel={setOpenModel}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          addNewContactHandler={addNewContactHandler}
          selectedItem={selectedItem}
          saveNumbers={(data) => saveNumbers(data)}
        />
      )}
    </Page>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter(
      (_blacklisted) =>
        String(_blacklisted?.numbers).toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        String(_blacklisted?.country).toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default BlackListNumbers;
