import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Dialog,
  Autocomplete,
  Chip,
  Grid,
  Stack,
  DialogTitle,
  IconButton,
  TextField,
  Button,
  DialogActions,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { countries } from '../../../utils/helper';
import CountriesInputSelector from '../../../components/CountriesInputSelector';
import { PATH_DASHBOARD } from '../../../routes/paths';

function BlackListNumberModel({ openModel, setOpenModel,setIsEdit, selectedItem, isEdit, updateNumbers,saveNumbers }) {
 
  const NewUserSchema = Yup.object().shape({});
  const [country, setCountry] = useState({ label: '', code: '' });
  const [blacklistedNumbers, setBlacklistedNumbers] = useState(selectedItem?.numbers || []);
  const defaultValues = {
    country: selectedItem?.country || '',
    numbers: selectedItem?.numbers || [],
  };

  useEffect(() => {
    if (isEdit) {
      if (selectedItem) {
        setCountry(selectedItem?.country);
        setBlacklistedNumbers(selectedItem?.numbers);
      } else {
        setOpenModel(false);
      }
    }
  }, [selectedItem]);

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });
  const {
    watch,
    setError,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (values) => {
    
   if(isEdit){
    values.country = selectedItem?.country;
     values.numbers = blacklistedNumbers;
    updateNumbers(values)
    setOpenModel(false)
    reset()

     }
     else{
      values.country = country.code
      values.numbers = blacklistedNumbers
      saveNumbers(values)
      setOpenModel(false)
      reset()
      // 
     }
    
    // setOpenModel(preState=>(!preState))
    // navigate(PATH_DASHBOARD.adminSettings.blacklistNumbers)

  };

  const selectedCountry = countries.find((item) => item.code === country);
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={openModel}
      onClose={() => {
        setOpenModel((prevState) => !prevState);
        setIsEdit(false)
      }}
      
    >
      <Stack direction="row" style={{ justifyContent: 'space-between', marginInline: 20 }}>
        <DialogTitle>Add number to blacklist</DialogTitle>
        <IconButton
          aria-label="delete"
          onClick={() => {
            setOpenModel((openPrev) => !openPrev);
            setIsEdit(false)
          }}
        >
          <Iconify icon={'ic:close'} width={20} height={20} />
        </IconButton>
      </Stack>

      <Container>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ mb: 5, mt: 5, p: 2 }}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={6} xs={12}>
                <Autocomplete
                  style={{ borderRadius: 10 }}
                  multiple
                  id="tags-filled"
                  freeSolo
                  options={blacklistedNumbers}
                  onChange={(e, value) => {
                    e.preventDefault()          
                    setBlacklistedNumbers(value);
                  }}
                  value={blacklistedNumbers}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip variant="outlined" key={index} label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(other) => <RHFTextField name="numbers" label="Blacklist Number(s)" {...other} />}
                />
              </Grid>
              <Grid item sm={12} md={6} xs={12}>
                <CountriesInputSelector
                  name="country"
                  label="Enter Country Name"
                  onChange={(e, value) => {
                    setCountry({ label: value.label, code: value.code });
                  }}
                  value={isEdit ? selectedCountry : country}
                  disabled={isEdit}
                />
              </Grid>

              <Grid item sm={12} md={12} xs={12}>
                <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                  {isEdit ? 'Update' : 'Save'}
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </FormProvider>
      </Container>
    </Dialog>
  );
}

export default BlackListNumberModel;
