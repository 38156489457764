/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-extend-native */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-globals */
export const validateRecipients = recipients => {
	const valid = [];
	const invalid = [];
	// eslint-disable-next-line array-callback-return
	recipients.map(el => {
		if (validReceiver(trimContact(el))) {
			valid.push(el)
		} else {
			invalid.push(el);
		}
	})

	return { valid, invalid }
}

export const trimContact = contact => {
	let number = contact;
	const specialChars = "!@#$^&%*()+=-[]\\/{}|:<>?,.";
	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < specialChars.length; i++) {
		number = contact.toString().replace(new RegExp(`\\${  specialChars[i]}`, "gi"), "");
	}
	return number;
}

const validReceiver = receiver => {
	const number = parseInt(trimContact(receiver),10);
	// eslint-disable-next-line no-restricted-globals
	if (!isNaN(number) && receiver.length > 9 && receiver.length <= 14) {
		return true;
	} 
		return false;
	
}

export const LoadingStatus = {
    Success:"success",
    Loading:"loading",
    Error:"error",
 }

 export const API_URLS = {
	 userUrl : "/users",
	 smsBillingUrl : '/sms-billing',
	 senderNamesUrl : '/sender-names',
	 blacklistedSenderNamesUrl : '/blacklisted-sender-names',
	 blacklistedNumbers : '/blacklisted-numbers',
	 groups : '/sms-groups',
	 developerApps : '/developer-apps',
	 smsBilling : '/sms-billing',
	 auth: '/harbor',
	 providedServices: '/services',
	 accounts: '/accounts',
	 permissions: '/permissions',
	 rolePermissions: '/roles-permissions',
	 invitations: '/invitations',
	 userAccounts: '/user-accounts',
	 messages: '/messages',
	 mobileNetworks: '/mobile-networks',
	 countrySettings: '/country-settings',
 }

 export const countries = [
	{ code: 'ET', label: 'Ethiopia', phone: '251' },
	{ code: 'GH', label: 'Ghana', phone: '233' },
  ];

  export const paymentMethods = [
	{
	  label: 'MTN Mobile Money',
	  value: 'Mobile Money'
	},
	{
	  label: 'Vodafone Cash',
	  value: 'VFCash'
	},
	{
	  label: 'Airtel Money',
	  value: 'AirtelMoney'
	},
	{
	  label: 'Bank Deposit',
	  value: 'Bank'
	},
  ]

  export function isStringAlphaNumeric(str) {  

	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < str.length; i++) {
	 const code = str.charCodeAt(i);
	  if (!(code > 47 && code < 58) && // numeric (0-9)
		  !(code > 64 && code < 91) && // upper alpha (A-Z)
		  !(code > 96 && code < 123)) { // lower alpha (a-z)
		return false;
	  }
	}
	return true;
  };

  export function isNumericDigits(value) {
    return /^-?\d+$/.test(value);
}

export function isNumberTest(str) {
	if (typeof str !== "string") return false // we only process strings!
	// could also coerce to string: str = ""+str
	return !isNaN(str) && !isNaN(parseFloat(str)) && !/^\s+|\s+$/g.test(str)
  }

 export  const isNumericTest3 = (num) => (typeof(num) === 'number' || typeof(num) === "string" && num.trim() !== '') && !isNaN(num);

 export const getExcelColumns = data => Object.keys(data);

 String.prototype.template = function (hash) {
    return this.replace(/{([^{}]*)}/g, function(match, key) {
        return hash[key.trim()] || "";
    });
};

export const buildPersonalizedMessages = ( cols, data, mgsTemplate ) => {
	const messages =[];
	data.map((item, key) => {
		const message = mgsTemplate.trim().template(item);
		const newM = { 
			"recipient" : item[cols[0]],
			"message": message,
			"length": message.length
		}
		return messages.push(newM);
	})

	return messages;
}

export const validateSenderName = (senderName) => {
	const alphanumericRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/;  // For alphanumeric (letters + numbers)
	const numericRegex = /^[0-9]+$/;            // Numeric check
	const alphabeticRegex = /^[a-zA-Z]+$/;      // Alphabetic check
  
	if (senderName.length < 3) {
	  return { isValid: false, message: "Sender name must have at least 3 characters." };
	}
  
	if (alphanumericRegex.test(senderName)) {
	  if (senderName.length > 11) {
		return { isValid: false, message: "Alphanumeric sender name must not exceed 11 characters." };
	  }
	  return { isValid: true, message: "" }; // Valid alphanumeric sender name
	}
  
	if (numericRegex.test(senderName)) {
	  if (senderName.length > 14) {
		return { isValid: false, message: "Numeric sender name must not exceed 14 characters." };
	  }
	  return { isValid: true, message: "" }; // Valid numeric sender name
	}
  
	if (alphabeticRegex.test(senderName)) {
	  if (senderName.length > 11) {
		return { isValid: false, message: "Alphabetic sender name must not exceed 16 characters." };
	  }
	  return { isValid: true, message: "" }; // Valid alphabetic sender name
	}
  
	return { isValid: false, message: "Sender name contains invalid characters or doesn't meet the criteria." };
  };

/**
 * Color with alpha channel
 */
export function varAlpha(color, opacity = 1) {
	const unsupported =
	  color.startsWith('#') ||
	  color.startsWith('rgb') ||
	  color.startsWith('rgba') ||
	  (!color.includes('var') && color.includes('Channel'));
  
	if (unsupported) {
	  throw new Error(
		`[Alpha]: Unsupported color format "${color}".
		 Supported formats are:
		 - RGB channels: "0 184 217".
		 - CSS variables with "Channel" prefix: "var(--palette-common-blackChannel, #000000)".
		 Unsupported formats are:
		 - Hex: "#00B8D9".
		 - RGB: "rgb(0, 184, 217)".
		 - RGBA: "rgba(0, 184, 217, 1)".
		 `
	  );
	}
  
	return `rgba(${color} / ${opacity})`;
  }
