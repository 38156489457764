// routes
import axios from 'axios';
import { useEffect } from 'react';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { PermissionsProvider } from './guards/PermissionGuard';

// ----------------------------------------------------------------------
// Items to note
// 1. NavConfig(src/layouts/dashboard/navbar/NavConfig), path(src/routes/path.js), routes(src/routes/index), all work together

export default function App() {
  useEffect(() => {
    async function getUserCountryIP() {
      const result = await axios.get('https://geolocation-db.com/json/');
      return result?.data;
    }
    getUserCountryIP();
  });

  return (
  
      <ThemeProvider>
        <ThemeColorPresets>
          <ThemeLocalization>
            <RtlLayout>
              <NotistackProvider>
                <MotionLazyContainer>
                  <ProgressBarStyle />
                  <ChartStyle />
                  {/* <Settings /> */}
                  <ScrollToTop />
                  <PermissionsProvider>
                    <Router />
                  </PermissionsProvider>
                </MotionLazyContainer>
              </NotistackProvider>
            </RtlLayout>
          </ThemeLocalization>
        </ThemeColorPresets>
      </ThemeProvider>
  );
}
