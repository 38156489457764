import React, { useState, useContext, useMemo } from 'react';
import { Container, Alert, AlertTitle } from '@mui/material';
import useAuth from '../hooks/useAuth';

export const PermissionsContext = React.createContext(null);

export const usePermissions = () => {
  const pc = useContext(PermissionsContext);
  if (pc === null) {
    throw new Error('usePermissions must be inside of PermissionsProvider');
  }
  return pc;
};

export const PermissionsProvider = ({ children }) => {
  const { user } = useAuth();
  const permissions = user?.permissions

  // const userPermissions  = user?.permissions
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const userPermissions  = permissions ?? []

  // const [permissions, setPermissions] = useState(['contact:add','contact:update']);

  const value = useMemo(() => ({ permissions:userPermissions }), [userPermissions]);

  return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>;
};

const checkMatch = (userPermissions, canProps) => {
  let match = false;
  const { permissions = [] } = canProps;
  const permissionsArr = Array.isArray(permissions) ? permissions : [permissions];
  if (permissionsArr.length === 0) {
    match = true;
  } else {
    match = permissionsArr.some((p) => userPermissions.includes(p));
  }
  return match;
};

export const Can = (props) => {
  const { children } = props;
  const { permissions: userPermissions } = usePermissions();
  const match = checkMatch(userPermissions, props);

  if (match) {
    return <>{children}</>;
  } 
    return null;
  
};

export const Switch = ({ children }) => {
  const { permissions: userPermissions } = usePermissions();

  let element = null;
  let match = false;

  React.Children.forEach(children, (child) => {
    if (!match && React.isValidElement(child) && child.type === Can) {
      element = child;
      match = checkMatch(userPermissions, child.props);
    }
  });

  return match ? element : null;
};
