// routes
import useAuth from '../../../hooks/useAuth';
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';
// ----------------------------------------------------------------------
const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  sms: getIcon('ic_sms'),
  developer: getIcon('ic_developer'),
  people: getIcon('ic_people'),
  permission: getIcon('ic_permission'),
};

const adminConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : USER
      {
        title: 'user management',
        path: PATH_DASHBOARD.admin.root,
        icon: ICONS.user,
        children: [
          { title: 'account', path: PATH_DASHBOARD.admin.account },
          { title: 'transactions', path: PATH_DASHBOARD.admin.transactions },
          { title: 'sender names', path: PATH_DASHBOARD.admin.senderNames },
          { title: 'users list', path: PATH_DASHBOARD.admin.userList },
          { title: 'accounts list', path: PATH_DASHBOARD.admin.accountList },
        ],
      },
      {
        title: 'admin settings',
        path: PATH_DASHBOARD.adminSettings.root,
        icon: ICONS.user,
        children: [
          { title: 'blacklisted numbers', path: PATH_DASHBOARD.adminSettings.blacklistNumbers },
          // { title: 'global spam filter', path: PATH_DASHBOARD.adminSettings.spamFilter },
          { title: 'blacklist senderNames', path: PATH_DASHBOARD.adminSettings.blacklistedSenderNames },
          { title: 'site branding', path: PATH_DASHBOARD.adminSettings.siteBranding },
          { title: 'features', path: PATH_DASHBOARD.adminSettings.features },
          { title: 'mobile networks', path: PATH_DASHBOARD.adminSettings.mobileNetworks },
          { title: 'country settings', path: PATH_DASHBOARD.adminSettings.countrySettings },
        ],
      },
      // {
      //   title: 'Configurations',
      //   path: PATH_DASHBOARD.configurations.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'default Settings', path: PATH_DASHBOARD.configurations.defaultSettings },
      //     { title: 'global spam Filter', path: PATH_DASHBOARD.configurations.spamFilter },
      //     { title: 'global Spam', path: PATH_DASHBOARD.configurations.globalSpam },
      //     { title: 'manage Notifications', path: PATH_DASHBOARD.configurations.manageNotifications },
      //     { title: 'manage Emails', path: PATH_DASHBOARD.configurations.manageEmails },

      //   ],
      // },

    ],
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'app',
    items: [
      {
        title: 'messaging',
        path: PATH_DASHBOARD.messaging.root,
        icon: ICONS.sms,
        children: [
          { title: 'Bulk Messaging', path: PATH_DASHBOARD.messaging.bulkMessages },
          { title: 'Personalized Messaging', path: PATH_DASHBOARD.messaging.personalized },
          { title: 'Messages Report', path: PATH_DASHBOARD.messaging.messagesReports },
          { title: 'Sender Names', path: PATH_DASHBOARD.messaging.senderNames, icon: ICONS.personalized },
          {
            title: 'Contact & Group',
            path: PATH_DASHBOARD.messaging.groupContact,
            icon: ICONS.analytics,
          },
          {
            title: 'Recharge',
            path: PATH_DASHBOARD.messaging.recharge,
            icon: ICONS.kanban,
          },
          {
            title: 'Billing History',
            path: PATH_DASHBOARD.messaging.billingHistory,
            icon: ICONS.ecommerce,
          },

        ],
      },
      // {
      //   title: 'mail',
      //   path: PATH_DASHBOARD.mail.root,
      //   icon: ICONS.mail,
      //   info: (
      //     <Label variant="outlined" color="error">
      //       +32
      //     </Label>
      //   ),
      // },
      // { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
      // { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
      // {
      //   title: 'kanban',
      //   path: PATH_DASHBOARD.kanban,
      //   icon: ICONS.kanban,
      // },
    ],
  },
  {
    subheader: 'Settings',
    items: [
      {
        title: 'Developer',
        path: PATH_DASHBOARD.settings.developer,
        icon: ICONS.developer,
      },
      {
        title: 'Sub Accounts',
        path: PATH_DASHBOARD.settings.subAccounts,
        icon: ICONS.people,
      },
      {
        title: 'Role Permission',
        path: PATH_DASHBOARD.settings.rolePermissionsList,
        icon: ICONS.permission,
      },
     

      // MANAGEMENT : E-COMMERCE
      // {
      //   title: 'e-commerce',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
      //     { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
      //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
      //     { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
      //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
      //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //     { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice },
      //   ],
      // },


    ],
  },
];


const clientConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'app',
    items: [
      {
        title: 'messaging',
        path: PATH_DASHBOARD.messaging.root,
        icon: ICONS.mail,
        children: [
          { title: 'Bulk Messaging', path: PATH_DASHBOARD.messaging.bulkMessages },
          { title: 'Personalized Messaging', path: PATH_DASHBOARD.messaging.personalized },
          { title: 'Messages Report', path: PATH_DASHBOARD.messaging.messagesReports },
          { title: 'Sender Names', path: PATH_DASHBOARD.messaging.senderNames, icon: ICONS.personalized },
          {
            title: 'Contact & Group',
            path: PATH_DASHBOARD.messaging.groupContact,
            icon: ICONS.analytics,
          },
          {
            title: 'Recharge',
            path: PATH_DASHBOARD.messaging.recharge,
            icon: ICONS.kanban,
          },
          {
            title: 'Billing History',
            path: PATH_DASHBOARD.messaging.billingHistory,
            icon: ICONS.ecommerce,
          },

        ],
      },
      // {
      //   title: 'mail',
      //   path: PATH_DASHBOARD.mail.root,
      //   icon: ICONS.mail,
      //   info: (
      //     <Label variant="outlined" color="error">
      //       +32
      //     </Label>
      //   ),
      // },
      // { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
      // { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
      // {
      //   title: 'kanban',
      //   path: PATH_DASHBOARD.kanban,
      //   icon: ICONS.kanban,
      // },
      // {
      //   title: 'Mobile Simulator',
      //   path: PATH_DASHBOARD.mobile_app.root,
      //   icon: ICONS.kanban,
      // },
    
    ],
  },
  {
    subheader: 'Settings',
    items: [
      {
        title: 'Developer',
        path: PATH_DASHBOARD.settings.developer,
        icon: ICONS.developer,
      },
      {
        title: 'Sub Accounts',
        path: PATH_DASHBOARD.settings.subAccounts,
        icon: ICONS.people,
      },
      {
        title: 'Role Permission',
        path: PATH_DASHBOARD.settings.rolePermissionsList,
        icon: ICONS.permission,
      },
     

      // MANAGEMENT : E-COMMERCE
      // {
      //   title: 'e-commerce',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
      //     { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
      //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
      //     { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
      //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
      //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //     { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice },
      //   ],
      // },


    ],
  },

  // APP
  // ----------------------------------------------------------------------
  
];

const NavConfig = () => {
  const { user } = useAuth();
  // console.log('user in config',user);
  if (user?.activeAccount?.userType.toLowerCase() === 'superadmin') {
    return adminConfig;
  } 
    return clientConfig;
  
};

export default NavConfig;
